<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>项目付款</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-merchants-info">
            <span>项目付款</span>
            <div class="sub-merchants-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                        ref="pName"
                            v-model="pName"
                            filterable
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="proChange">
                        <el-option
                                v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @visible-change="clickProject"
                            @change="societyChange">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                        v-model="pid.time"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                        v-model="pid.chairman"
                        :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                        disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">下游协议</div>
                    <el-select
                            v-model="xid"
                            placeholder="请选择所属下游协议"
                            @visible-change="clickProject"
                            @change="htChange">
                        <el-option
                                v-for="item in htOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">付款类型</div>
                    <el-radio-group v-model="payment_type" disabled>
                        <el-radio :label="1">会议基金</el-radio>
                        <el-radio :label="2">专项基金</el-radio>
                    </el-radio-group>
                </div>
                <div class="input-item">
                    <div class="input-item-title">委托项目</div>
                    <el-input
                            v-model="pay_item"
                            placeholder="请输入委托项目"
                            disabled></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">付款金额</div>
                    <el-input
                            v-model="pay_amount"
                            type="number"
                            @mousewheel.native.prevent
                            placeholder="请输入付款金额"
                            @input="setAmount(0)"></el-input>
                    <div class="money">万元</div>

                    <div class="money-count">
                        本合同待支付余额<span>{{ surplus }}</span>万元,
                        {{payment_type === 1 ? '会议' : '专项'}}基金可用余额<span>{{ payment_type === 1 ? meet : self }}</span>万元
                    </div>
                </div>
                <div class="tip-red"
                     v-if="parseFloat(Number(pay_amount).toFixed(6))>parseFloat(Number(surplus).toFixed(6))">
                    付款金额大于现有会议总可用金额
                </div>
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">其中会议金额</div>-->
                <!--<el-input-->
                <!--v-model="meeting_decimal"-->
                <!--type="number"-->
                <!--placeholder="请输入其中会议金额"-->
                <!--@input="setAmount(2)"-->
                <!--&gt;</el-input>-->
                <!--<div class="money">万元</div>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">其中专项金额</div>-->
                <!--<el-input-->
                <!--v-model="self_amount"-->
                <!--type="number"-->
                <!--placeholder="请输入其中专项金额"-->
                <!--@input="setAmount(1)"-->
                <!--&gt;</el-input>-->
                <!--<div class="money">万元</div>-->
                <!--</div>-->
                <div class="input-item">
                    <div class="input-item-title">收款单位</div>
                    <el-autocomplete
                            class="inline-input"
                            v-model="payee"
                            :fetch-suggestions="querySearch"
                            :trigger-on-focus="false"
                            @select="payeeChange"
                            value-key="name"
                            placeholder="请输入收款单位"></el-autocomplete>
                </div>
                <div class="input-item">
                    <div class="input-item-title">收款单位开户行</div>
                    <el-input
                            v-model="bank_name"
                            placeholder="请输入收款单位开户行"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">收款单位账号</div>
                    <el-input
                            v-model="bank_code"
                            placeholder="请输入收款单位账号"
                    ></el-input>
                </div>
                <!--<div class="input-item">-->
                <!--<div class="input-item-desc">费用明细单</div>-->
                <!--<el-input  v-model="detail" placeholder="请输入费用明细单"></el-input>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">付款交接文件</div>-->
                <!--<el-checkbox-group v-model="fukuan_jiaojie">-->
                <!--<el-checkbox label="1">支出凭单</el-checkbox>-->
                <!--<el-checkbox label="2">下游专票</el-checkbox>-->
                <!--<el-checkbox label="3">下游协议</el-checkbox>-->
                <!--<el-checkbox label="4">项目明细</el-checkbox>-->
                <!--</el-checkbox-group>-->
                <!--</div>-->
                <!--<div class="input-item">-->
                <!--<div class="input-item-title">结算交接文件</div>-->
                <!--<el-checkbox-group v-model="jiesuan_jiaojie">-->
                <!--<el-checkbox label="1">会议结算单</el-checkbox>-->
                <!--<el-checkbox label="2">电子结算单</el-checkbox>-->
                <!--</el-checkbox-group>-->
                <!--</div>-->
                <div class="input-item">
                    <div class="input-item-desc">备注</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="remark"
                            placeholder="请输入备注"
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">附件</div>
                    <div>
                        <div v-if="files.length > 0" class="upfile-list">
                            <ul v-for="(file, index) in files" :key="index">
                                <li class="flex-center between-box">
                                    <a :href="file.filePath | filePath" class="ovHide" target="_blank">{{ file.fileName
                                        }}</a>
                                    <i class="el-icon-delete" @click="closeFile(index)"></i>
                                </li>
                            </ul>
                        </div>
                        <el-upload
                            :action="uploadPath"
                            :before-upload="beforeAvatarUpload"
                            :data="QiniuData"
                            :multiple="true"
                            :on-error="uploadError"
                            :on-success="uploadSuccess"
                            :show-file-list="false">
                            <el-button size="small" type="primary">点击上传文件</el-button>
                            <div slot="tip" class="el-upload__tip">可上传图片、文档、压缩包等</div>
                        </el-upload>
                    </div>
                </div>
                <approvalList
                        :typeClassify="9"
                        @setMember="setMember"
                        :isNoHave="!id"
                        :approvalMembers="approvalMembers"
                        :copyMembers="copyMembers"
                        :society="society"
                        @setApproval="setApproval"
                ></approvalList>

                <el-button type="primary" @click="submitApply" :loading="requestState" :disabled="requestState" >提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";
    import {genUpToken, qiniuaddr, uploadPath} from "../../tools/CryptoJS";
    import {getQNFileKey, getQNFileName, webUrl} from "../../tools";
    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "pay",
        data() {
            return {
                id: "",
                sign_code: "",
                //  学会数据
                society: "",
                societyOptions: [],
                //  会议数据
                pid: "",
                pName: "",
                projectOptions: [],
                xid: "",
                payment_type: "",
                htOptions: [],
                pay_item: "",

                surplus: "0",
                meet: "0",
                self: "0",
                all_money: "0",

                pay_amount: "",
                self_amount: "",
                meeting_decimal: "",
                payee: "",
                fukuan_jiaojie: [],
                jiesuan_jiaojie: [],
                detail: "",
                shiyou: "",
                bank_name: "",
                bank_code: "",
                remark: "",

                shenpi: "",
                copy: "",
                approvalMembers: [],
                copyMembers: [],
                restaurants: [],

                requestState:false,

                QiniuData: {
                    fname: "", //  文件名
                    key: "", //文件名字处理
                    token: "", //七牛云token
                },
                qiniuaddr:qiniuaddr, // 七牛云的图片外链地址 你的七牛云里配置有
                uploadPath:uploadPath, // 七牛云的图片外链地址 你的七牛云里配置有
                files: [],
            };
        },
        created() {
            this.QiniuData.token = genUpToken();
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.$emit("setIndex", [4, 13]);
            this.init();
        },
        methods: {
            querySearch(queryString, cb) {
                const restaurants = this.restaurants;
                const results = queryString
                    ? restaurants.filter(this.createFilter(queryString))
                    : restaurants;
                // 调用 callback 返回建议列表的数据
                cb(results);
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (
                        restaurant.payee
                            .toLowerCase()
                            .indexOf(queryString.toLowerCase()) !== -1
                    );
                };
            },
            payeeChange(e) {
                this.payee = e.payee
                this.bank_name = e.bank_name
                this.bank_code = e.bank_no
            },
            setAmount(type) {
                if (type === 0) {
                    this.pay_amount = this.pay_amount.replace(/[^0-9 .]/g, "");
                    this.self_amount = "0";
                    this.meeting_decimal = "0";
                } else if (type === 1) {
                    if (this.pay_amount) {
                        if (this.self_amount) {
                            this.meeting_decimal = parseFloat((
                                Number(this.pay_amount).toFixed(6) - Number(this.self_amount).toFixed(6)).toFixed(6));
                        } else {
                            this.meeting_decimal = parseFloat(Number(this.pay_amount).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.pay_amount = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                } else {
                    if (this.pay_amount) {
                        if (this.meeting_decimal) {
                            this.self_amount = parseFloat((
                                Number(this.pay_amount).toFixed(6) - Number(this.meeting_decimal).toFixed(6)).toFixed(6));
                        } else {
                            this.self_amount = parseFloat(Number(this.pay_amount).toFixed(6));
                        }
                    } else {
                        if (this.self_amount && this.meeting_decimal) {
                            this.pay_amount = parseFloat((
                                Number(this.meeting_decimal).toFixed(6) + Number(this.self_amount).toFixed(6)).toFixed(6));
                        }
                    }
                }
            },
            submitApply() {
                if (Number(this.all_money) - Number(this.pay_amount) > 0) {
                    this.subApproval();
                } else {
                    this.$confirm("本次付款为最后一笔付款，请交接相关文件", "温馨提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(()=>{
                        this.subApproval();
                    })
                }
            },
            //  提交审核
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }
                if (!this.xid) {
                    this.$message.error("请选择下游协议");
                    return;
                }
                if (!this.pay_item) {
                    this.$message.error("请输入委托项目");
                    return;
                }
                if (!this.pay_amount) {
                    this.$message.error("请选择付款金额");
                    return;
                }
                if (this.meeting_decimal === "") {
                    this.$message.error("请输入其中会议金额");
                    return;
                }
                if (this.self_amount === "") {
                    this.$message.error("请输入其中专项金额");
                    return;
                }
                if (!this.payee) {
                    this.$message.error("请选择收款单位");
                    return;
                }
                if (!this.bank_name) {
                    this.$message.error("请输入开户行");
                    return;
                }
                if (!this.bank_code) {
                    this.$message.error("请输入银行账户");
                    return;
                }
                //                if (!this.detail) {
                //                    this.$message.error("请输入费用明细单")
                //                    return
                //                }

                //                if (this.jiesuan_jiaojie.length===0) {
                //                    this.$message.error("请选择结算交接文件")
                //                    return
                //                }
                //                if (!this.remark) {
                //                    this.$message.error("请输入备注")
                //                    return
                //                }
                if (this.shenpi.indexOf("-1") !== -1) {
                    this.$message.error("请完善财务人员");
                    return;
                }
                let arr = this.shenpi.split(",");
                if (arr[0] === arr[1]) {
                    this.$message.error("审批人与财务人员不能相同");
                    return;
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalFk({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        xid: this.xid,
                        sign_code: this.sign_code,
                        pay_item: this.pay_item,
                        payment_type: this.payment_type,
                        pay_amount: this.pay_amount,
                        self_amount: this.self_amount,
                        meeting_decimal: this.meeting_decimal,
                        payee: this.payee,
                        bank_name: this.bank_name,
                        bank_no: this.bank_code,
                        detail: this.detail,
                        fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                        jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                        files: JSON.stringify(this.files),
                        remark: this.remark,
                        shenpi: this.shenpi,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                xid: this.xid,
                                sign_code: this.sign_code,
                                pay_item: this.pay_item,
                                payment_type: this.payment_type,
                                pay_amount: this.pay_amount,
                                self_amount: this.self_amount,
                                meeting_decimal: this.meeting_decimal,
                                payee: this.payee,
                                bank_name: this.bank_name,
                                bank_no: this.bank_code,
                                detail: this.detail,
                                fukuan_jiaojie: this.fukuan_jiaojie.join(","),
                                jiesuan_jiaojie: this.jiesuan_jiaojie.join(","),
                                files: JSON.stringify(this.files),
                                remark: this.remark,
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push({
                            name: "pay",
                            query: {id: res.data.id, type: 1},
                        });
                        //                    this.backPage()
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },

            //  选择会议
            proChange(index) {
                let value = this.projectOptions[index];
                value.time =
                    this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                    " 至 " +
                    this.$tools.ZHDate(new Date(value.end_time * 1000));
                this.pName = value.name;
                this.pid = value;
                this.society = "";
                this.xid = "";
                this.pay_item = "";
                this.payment_type = "";
                this.surplus = "0";
                this.meet = "0";
                this.self = "0";
                this.all_money = "0";

                this.getSociety();
            },
            societyChange() {
                this.xid = ""
                this.pay_item = "";
                this.payment_type = "";
                this.surplus = "0";
                this.meet = "0";
                this.self = "0";
                this.all_money = "0";
            },
            //  选择合同
            htChange(id) {
                let item = this.htOptions.filter((item) => {
                    return item.id === id;
                });
                this.pay_item = item[0].payment;
                this.payment_type = item[0].payment_type;
                this.surplus = item[0].surplus;
                this.meet = item[0].meet;
                this.self = item[0].self;
                this.all_money = item[0].all_money;
            },
            //  选择所属学会点开后没选择
            clickProject(view) {
                if (!this.pid && view) {
                    this.$message.error("请选择会议名称");
                }
            },
            //  获取合同
            getContractList() {
                this.$api.apiContent
                    .getContractList({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map((item) => {
                            item.name =
                                item.corporate_name +
                                "(" +
                                this.$tools.ZHDate(new Date(item.add_time * 1000), 6) +
                                ")";
                        });
                        this.htOptions = res.data;
                        if (res.data.length > 0) {
                            if (this.xid) {
                                let item = this.htOptions.filter((item) => {
                                    return item.id === this.xid;
                                })[0];
                                this.xid = item.id;
                                this.pay_item = item.payment;
                                this.payment_type = item.payment_type;
                                this.surplus = item.surplus;
                                this.self = item.self;
                                this.meet = item.meet;
                                this.all_money = item.all_money;
                            } else {
                                this.xid = res.data[0].id;
                                this.pay_item = res.data[0].payment;
                                this.payment_type = res.data[0].payment_type;
                                this.surplus = res.data[0].surplus;
                                this.self = res.data[0].self;
                                this.meet = res.data[0].meet;
                                this.all_money = res.data[0].all_money;
                            }
                        } else {
                            this.xid = "";
                            this.pay_item = "";
                            this.payment_type = "";
                            this.surplus = "0";
                            this.all_money = "0";
                        }
                    })
                    .catch(() => {
                        this.htOptions = [];
                        this.xid = "";
                        this.pay_item = "";
                        this.payment_type = "";
                        this.surplus = "0";
                        this.meet = "0";
                        this.self = "0";
                        this.all_money = "0";
                    });
            },
            //  获取学会
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            //  获取学会
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) + '/' + item.chairman + '/' + item.name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                this.$api.apiContent
                    .getPayCompanys({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item => {
                            item.name = item.payee + "(" + item.bank_name + "/" + item.bank_no + ")"
                        })
                        this.restaurants = res.data;
                    })
                    .catch(() => {
                        this.restaurants = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getFukuan({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//            this.approvalMembers = res.data.shenpi;
//            this.shenpi = res.data.shenpi
//              .map((item) => {
//                return item.user_id;
//              })
//              .join(",");
                            this.copyMembers = res.data.copy;
                            this.copy = res.data.copy
                                .map((item) => {
                                    return item.user_id;
                                })
                                .join(",");
                            this.sign_code = res.data.sign_code;
                            this.society = res.data.sid;
                            this.xid = res.data.xid;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000)) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000));
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000)) + '/' + res.data.project.chairman + '/' + res.data.project.name;
                            this.pay_item = res.data.pay_item;
                            this.pay_amount = parseFloat(Number(res.data.pay_amount).toFixed(6));
                            this.self_amount = res.data.self_amount;
                            this.meeting_decimal = res.data.meeting_decimal;
                            this.payee = res.data.payee;
                            //                        this.fukuan_jiaojie = res.data.fukuan_jiaojie
                            //                        this.jiesuan_jiaojie = res.data.jiesuan_jiaojie
                            this.shiyou = res.data.shiyou;
                            this.detail = res.data.detail;
                            this.bank_name = res.data.bank_name;
                            this.bank_code = res.data.bank_no;
                            this.remark = res.data.remark;
                            this.files = res.data.files ? JSON.parse(res.data.files) : [];
                            this.getSociety();
                        })
                        .catch(() => {
                        });
                }
            },
            //  返回
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
            //  上传成功
            uploadSuccess(response, file, fileList) {
                console.log(file);
                console.log(fileList);
                this.files.push({
                    filePath: `${this.qiniuaddr}/${response.key}`,
                    //在这里你就可以获取到上传到七牛的外链URL了
                    fileName: file.name,
                });

                this.loading.close();
            },
            closeFile(position) {
                this.$confirm("此操作将删除该文件, 是否继续?", "温馨提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(() => {
                        this.files.splice(position, 1);
                    })
                    .catch();
            },
            //  上传失败
            uploadError() {
                this.loading.close();
                this.$message({
                    message: "上传出错，请重试！",
                    type: "error",
                    center: true,
                });
            },
            beforeAvatarUpload(file) {
                //                const isJPG = file.type === 'application/pdf';
                //                if (!isJPG) {
                //                    this.$message.error('只能上传PDF格式!');
                //                    return
                //                }
                //这里的key给加上了时间戳，目的是为了防止上传冲突
                this.QiniuData.fname = getQNFileName(file.name)
                this.QiniuData.key = getQNFileKey(file.name)
                this.loading = this.$loading({
                    lock: true,
                    text: "上传中，请稍后",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            },
        },
        filters:{
            filePath(url) {
                return webUrl(url)
            },
        },
        watch: {
            society() {
                this.getContractList();
            },
        },
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }

        .sub-merchants-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;

            span {
                color: #000000;
                font-size: 24px;
            }

            .sub-merchants-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .tip-red {
                    color: red;
                    font-weight: bold;
                    margin-left: 23%;
                    margin-bottom: 20px;
                }
                .input-item {
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;

                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;

                    }
                    .red {
                        color: red;
                        font-weight: bold;
                    }
                    .money-count {
                        flex-shrink: 0;
                        margin-left: 10px;
                        font-size: 16px;
                    }

                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }

                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .el-autocomplete,
                    .el-input,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }

                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
